export default {
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    idKey: 'id', // 默认勾选判断字段
    resizable: false, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
