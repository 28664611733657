<script>
import TablePage from '../../../../../components/table_page';
import Modal from '../../../../../components/modal';
import configs from './config';
import Form from '../form';
// import request from '../../../../../utils/request';

export default {
  name: 'proportion',
  extends: TablePage,
  data() {
    return {
      requestUrl: '/mdm/mdmProductLevelController/pageList',
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  components: {
    Modal,
    Form,
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    // 按钮点击事件
    modalClick({ val, row }) {
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.formConfig = {
          type: val.code,
          id: null,
        };
        this.openModal();
      } else if (val.code === 'edit') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '编辑';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.openModal();
      } else if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.formConfig.code = 'view';
        this.modalConfig.title = '查看';
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.openModal();
      } else if (val.code === 'head-export' || val.code === 'head-import') {
        return false;
      }
    },
    // // 删除操作
    // deleteClick({ row }) {
    //   const ids = row ? [row.id] : this.selection;

    //   const title = '此操作将永久删除此数据, 是否继续?';
    //   const url = '/mdm/mdmProductLevelController/delete';
    //   const msg = '删除';

    //   const data = {
    //     params: ids,
    //     url,
    //     title,
    //     msg,
    //   };
    //   this.messageBox(data);
    // },
  },
  created() {
    this.getConfigList('mdm_product_hierarchy');
  },
};
</script>
