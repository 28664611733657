<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    await this.getFormRule('mdm_product_hierarchy_form');
    const parentCode = this.getRule('parentCode');
    parentCode.restful = '/mdm/baseTreeController/productLevelTree';
    parentCode.headers = { functionCode: 'select-product-level-up' };
    parentCode.props = {
      ...parentCode.props,
      options: [],
      props: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
    };

    // if (this.formConfig.type === 'edit') {

    // }

    // this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view') {
      request
        .get('/mdm/mdmProductLevelController/detail', {
          id: this.formConfig.id,
        })
        .then((res) => {
          if (res.success) {
            parentCode.restfulParams = { notUnderProductLevelCode: this.formConfig.id, excludeCodeAndChildren: res.result.productLevelCode };
            this.setValue(res.result);
            this.row = res.result;
          }
        });
    }
  },
  methods: {
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmProductLevelController/save';
        let params = { ...formData };

        if (this.formConfig.type === 'edit') {
          url = '/mdm/mdmProductLevelController/update';
          params = Object.assign(this.row, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('resetTree');
          }
        });
      }
    },
  },
};
</script>
